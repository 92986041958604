<template>
  <div class="req-temp-container client-user-form">
    <vs-card>
      <div slot="header">
        <h3 class="font-normal">Details <span class="mid-blue">*</span></h3>
      </div>
      <vs-row>
        <p v-if="!userID" class="mb-8">Add your own reference and notes for a new API token, tap save and the token will be created - you'll need to come back to this screen to view/copy/roll the token after it has been created.</p>
        <div class="input-grp mb-5">
          <label class="w-full">Your system reference</label>
          <vs-input class="w-full" v-validate="'required'" size="large" v-model="formData.systemName" name="reference" />
          <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
        </div>
        <div class="input-grp mb-5">
          <label class="w-full">Description</label>
          <vs-textarea class="mb-1 w-15" v-model="formData.description" rows="1" />
        </div>
      </vs-row>
      <vs-row v-if="userID">
        <div class="flex w-full">
          <vs-input class="grow" v-model="apiUserToken" disabled style="margin-right: 40px" />
          <span @click="copyTokenToClipboard">
            <vs-tooltip text="Copy to clipboard" class="pointer">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </vs-tooltip>
          </span>
          <span @click="tokenPopupActive = true" class="ml-2">
            <vs-tooltip text="Roll token" class="pointer">
              <vs-icon icon-size="small" class="refresh-icon text-grey">refresh</vs-icon>
            </vs-tooltip>
          </span>
        </div>
      </vs-row>
    </vs-card>

    <vs-row class="mt-8">
      <vs-col vs-w="12" class="flex justify-end">
        <vs-button size="large" class="primary mr-8" @click="saveUser" v-round>Save</vs-button>
        <vs-button v-if="userID" size="large" @click="popupDelActive = true" class="only-border-btn mr-8" v-round>Delete token</vs-button>
        <a color="danger" @click="onCancel" class="edit-profile flex items-center text-base" v-round>Cancel</a>
      </vs-col>
    </vs-row>

    <leave-popup :title="'Leave without applying token change?'" :content="'Changes does not apply until you click save'" :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <delete-popup :popup-active="popupDelActive" :warningContent="warningContent" :deleteWhat="deleteWhat" :buttonText="buttonText" :found="warningFound" @handleDeleteClick="delActions"></delete-popup>

    <vs-popup
      title="Roll API token?"
      class="close-icon-hidden custom-popup-decline"
      :active.sync="tokenPopupActive"
      style="color: rgb(255, 255, 255)"
      background-color="rgba(255,255,255,.6)"
    >
      <p class="text-gray-font">
        You're about to change the API token, make sure you update any API integrations using this token.
      </p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="getToken()" action-btn>Confirm</vs-button>
        <vs-button color="danger" @click="tokenPopupActive = false" size="large" action-btn class="ml-2">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import DeletePopup from "@/views/components/DeletePopup";

export default {
  components: { PopUp, LeavePopup, DeletePopup },

  data() {
    return {
      apiUserToken: "",
      userID: "",
      popupActive: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: "", // determines the next route,
      formData: {
        userRole: "apiUser",
        userType: "apiUser",
        systemName: "",
        description: "",
      },
      popupDelActive: false,
      warningFound: true,
      warningContent: "By deleting this token you are permanently removing their access to this platform.",
      deleteWhat: "token",
      buttonText: "Delete token",
      id: "",
      tokenPopupActive: false,
      tokenUpdate: false,
    };
  },

  methods: {
    ...mapActions("partner", ["createStaff", "deleteStaff", "fetchApiUser", "generateApiToken", "updateStaffDetailsById"]),
    ...mapActions("admin", ["checkLogin"]),

    copyTokenToClipboard() {
      navigator.clipboard.writeText(this.apiUserToken).then(() => {
        this.showMessage("Copy to Clipboard", "Copied text to clipboard", "success", "icon-check-circle");
      });
    },

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup", "decline-popup");
      } else {
        document.body.classList.remove("custom-popup", "decline-popup");
      }
    },

    onCancel() {
      if (this.tokenUpdate) {
        this.popupActive = true;
      } else {
        this.isSaved = true;
        this.$router.push({ name: "integrations" });
      }
    },

    async getUserById(id) {
      await this.fetchApiUser(id).then((result) => {
        this.formData.userRole = result.data.data.userType;
        this.formData.userType = result.data.data.userType;
        this.formData.systemName = result.data.data.systemName;
        this.formData.description = result.data.data.description;
        this.apiUserToken = result.data.data.thirdPartyAcessToken;
      }).catch((ex) => {
        this.showMessage("Token", ex.data.message, "danger", "icon-alert-circle");
        this.$router.push({ name: "integrations" });
      });
    },

    async saveUser() {
      this.$vs.loading();
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.formData.accountStatus = "Active";
          let obj = {
            id: this.$route.params.id,
            data: this.formData,
          };

          if (this.$route.params.id) {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.editedByAdmin = this.user.adminId;
            }

            if (this.tokenUpdate) {
              obj.data.thirdPartyAcessToken = this.apiUserToken;
            }

            this.updateUser(obj);
          } else {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.createdByAdmin = this.user.adminId;
            }

            this.storeUser(obj);
          }

          this.isSaved = true;
        }
      });

      this.$vs.loading.close();
    },

    async updateUser(obj) {
      this.$vs.loading();
      await this.updateStaffDetailsById(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been updated successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "integrations" });
      }).catch((ex) => {
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
        this.$vs.loading.close();
      });
    },

    async storeUser(obj) {
      this.$vs.loading();
      await this.createStaff(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been created successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "integrations" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
      });
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.handleDelete();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async handleDelete() {
      this.$vs.loading();
      await this.deleteStaff(this.id).then((result) => {
        this.isSaved = true;
        this.$vs.loading.close();
        this.showMessage("Successful", result.data.message, "success", "icon-check-circle");
        this.$router.push({ name: "integrations" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
      });
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },

    async getToken() {
      this.tokenPopupActive = false;
      this.$vs.loading();

      await this.generateApiToken(this.userID).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Token", "Token generated successfully, please click save to apply changes.", "success", "icon-check-circle");
        this.apiUserToken = result.data.data;
        this.tokenUpdate = true;
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Token", ex.data.message, "danger", "icon-alert-circle");
      });
    },

    popActions(event) {
      switch (event) {
        case "leave":
          if (this.nextObj && this.nextObj != "") {
            this.nextObj();
          }

          this.isSaved = true;
          this.$router.push({ name: "integrations" });
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.tokenUpdate && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },

  watch: {
    tokenPopupActive(val) {
      this.addPopUpClass(val);
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.userID = this.$route.params.id;
      this.getUserById(this.$route.params.id);
    }
    this.checkAdminLogin();
  },

  created() {
    this.id = this.$route.params.id;
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    }
  },
};
</script>

